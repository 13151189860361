import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ProductSection from '../components/ProductSection';
import MainHeading from '../components/MainHeading';
import { Box, Container } from '@mui/material';

const Home = () => {
    const navigate = useNavigate();

    const handleFormSubmit = (productTitle, role, company) => {
      navigate(`/coaches?product=${productTitle}&role=${role}&company=${company}`, {
        state: { productTitle, role, company }
    });
    };
    console.log("Rendering Ace with Mocks only");
    return (
        <>
          {/* <Helmet>
            <title>EastBadge - Ace Your Job Prep with Expert Mock Interviews</title>
            <meta name="description" content="Practice mock interviews with experts from your target company and role. Receive personalized feedback and ace your interviews." />
          </Helmet> */}
          <Container maxWidth="lg" sx={{ minHeight: '120vh', py: 4, display: 'flex', flexDirection: 'column', justifyContent: 'center', pb: 50 }}>
            <MainHeading />
            <Box sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: 'space-around',
              mt: 4,
              gap: { xs: '20px', sm: '40px', md: '130px' },
              flexWrap: 'wrap',
              position: 'relative'
            }}>
              <ProductSection title="Ace with Mocks" onFormSubmit={handleFormSubmit} />
              {/* Gradient Separator */}
              {/* <Box sx={{
                display: { xs: 'none', sm: 'block' },
                position: 'absolute',
                top: '0',
                bottom: '0',
                left: '50%',
                height: '100%',
                width: '2px',
                background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.08) 50%, rgba(0,0,0,0) 100%)'
              }} />
              <ProductSection title="Burnout to Break Free" onFormSubmit={handleFormSubmit} /> */}
            </Box>
          </Container>
        </>
    );
};

export default Home;
